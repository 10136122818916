<template>
  <div>
    <remote-search-select
      :id="id"
      v-model="product"
      popper-append-to-body
      resource="products"
      class="w-full"
      fetch-handler="getAll"
      :computed-fields="['custom_id', 'name']"
      :compute-description="getItemDescription"
      :loading="loading || barcodeLoading"
      :modify-query="productSearchQuery"
      :placeholder="
        isScanning ? $t('pages.inventory.stock.scanner_button.placeholder') : ''
      "
      :result-filter="resultFilter"
      :disabled="isScanning"
      @resource-set="selectProduct"
    >
      <template #append>
        <el-button
          ref="scannerButton"
          plain
          type="primary"
          class="py-2 px-3 h-10"
          :title="
            isScanning
              ? $t('pages.inventory.stocks.scanner_button.manual_input')
              : $t('pages.inventory.stocks.scanner_button.use_scanner')
          "
          @click="toggleScanning"
        >
          <svgicon
            :src="
              require(`@/assets/icons/${
                isScanning ? 'th-icon-close' : 'th-barcode-scan'
              }.svg`)
            "
            class="fill-current"
            :style="{ height: '20px', width: '20px' }"
          />
        </el-button>
      </template>
    </remote-search-select>

    <multi-products-dialog
      class="leading-normal"
      :products="multipleProducts"
      :visible="multipleProductsModal"
      @select-product="selectProductInModal"
      @cancel-dialog="cancelDialog"
    />
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'
import RemoteSearchSelect from '@/components/select/remote-search'
import MultiProductsDialog from '../../components/multi-products-dialog'
import barcodeReaderMixin from '@/mixins/barcode-reader'
import thIconBarcodeScan from '@/assets/icons/th-barcode-scan.svg'
import thIconClose from '@/assets/icons/th-icon-close.svg'
import { isTablet } from '@/utils/general'

export default {
  components: {
    MultiProductsDialog,
    RemoteSearchSelect
  },

  props: {
    modelValue: {
      type: String,
      default: undefined
    },
    resultFilter: {
      type: Function,
      default: (results) => results
    },
    loading: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: 'product'
    },
    customProductSearchQuery: {
      type: Function,
      default: null
    }
  },

  setup(props, { emit }) {
    const { t } = useI18n()
    const resourceFetch = inject('resourceFetch')
    const barcodeLoading = ref(false)
    const isScanning = ref(isTablet())
    const multipleProductsModal = ref(false)
    const multipleProducts = ref([])
    const product = computed({
      get: () => {
        return props.modelValue
      },
      set: (val) => {
        emit('update:modelValue', val)
      }
    })

    function selectProduct(data) {
      emit('select-product', data)
    }

    async function fetchProductsByQueryProp(barcode, prop) {
      try {
        const searchQuery = productSearchQuery()
        const { q, ...query } = searchQuery.query
        const { products = [] } = await resourceFetch({
          resource: 'products',
          query: {
            ...query,
            [prop]: barcode
          }
        })
        return products
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        return []
      }
    }

    function productSearchQuery(q) {
      if (props.customProductSearchQuery) {
        return props.customProductSearchQuery(q)
      }
      return {
        query: {
          limit: 50,
          deleted: false,
          exclude_system_products: true,
          stockable: true,
          is_service: false,
          type: ['product', 'variant', 'linked_product', 'composed_product'],
          q
        }
      }
    }

    async function handleBarcode(barcode) {
      isScanning.value = false
      if (barcode && barcode.length < 4) {
        // this.resetForm()
        return ElMessage({
          type: 'error',
          message: t('pages.inventory.stocks.errors.invalid_barcode')
        })
      }

      barcodeLoading.value = true
      const [productsByBarcode, productsByCustomId] = await Promise.all([
        fetchProductsByQueryProp(barcode, 'barcode'),
        fetchProductsByQueryProp(barcode, 'custom_id')
      ])

      const results = [...productsByBarcode, ...productsByCustomId]

      barcodeLoading.value = false

      if (!results || !Array.isArray(results) || !results.length) {
        return ElMessage({
          type: 'error',
          message: t('pages.inventory.stocks.errors.no_product')
        })
      }

      if (results.length > 1) {
        multipleProductsModal.value = true
        multipleProducts.value = results
        return
      }

      const result = {
        id: results[0].id,
        barcode: results[0].barcode,
        name: results[0].name,
        label: results[0].name,
        custom_id: results[0].custom_id
      }

      product.value = result.id
      selectProduct(result)
    }

    barcodeReaderMixin(handleBarcode)

    return {
      barcodeLoading,
      isScanning,
      multipleProductsModal,
      multipleProducts,
      selectProduct,
      product,
      productSearchQuery
    }
  },

  data() {
    return {
      thIconBarcodeScan,
      thIconClose
    }
  },

  computed: {
    ...mapGetters({
      clientAccountConfiguration: 'Config/getClientAccountConfiguration'
    }),
    showItemDescription() {
      return !safeGet(
        this.clientAccountConfiguration,
        'ui_configurations.dashboard.products.attributes_in_name',
        false
      )
    }
  },

  methods: {
    getItemDescription(item) {
      if (this.showItemDescription) {
        return Object.values(item.attributes || {}).join(' | ')
      }
    },
    toggleScanning() {
      this.isScanning = !this.isScanning
      this.$refs.scannerButton.$el.blur()
    },

    selectProductInModal(product) {
      this.product = product.id
      this.selectProduct({
        id: product.id,
        barcode: product.barcode,
        name: product.name,
        label: product.name,
        custom_id: product.custom_id
      })
      this.multipleProductsModal = false
    },

    cancelDialog() {
      this.multipleProducts = []
      this.multipleProductsModal = false
    }
  }
}
</script>
