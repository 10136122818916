<template>
  <div>
    <th-table
      :data="historyList"
      :columns="columns"
      configuration-name="settings.headerFilters.stock-actions-history"
      disable-pagination
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'
import ThTable from '@/components/table'

export default {
  components: {
    ThTable
  },

  props: {
    historyList: {
      type: Array,
      required: false,
      default: () => []
    },

    hasReasons: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      clientAccountConfiguration: 'Config/getClientAccountConfiguration'
    }),
    showAttributes() {
      return (
        safeGet(
          this.clientAccountConfiguration,
          'ui_configurations.dashboard.products.attributes_in_name'
        ) === false
      )
    },
    columns() {
      return [
        {
          label: this.$t('pages.products.all.headers.name'),
          key: 'name',
          visible: true,
          formatter: (value) => value.name || '--'
        },
        {
          label: this.$t('pages.products.all.headers.attributes'),
          key: 'attributes',
          visible: true,
          formatter: this.getAttributes
        },
        {
          label: this.$t(
            'pages.inventory.stocks.history.details.article_number'
          ),
          key: 'custom_id',
          visible: true,
          formatter: (value) => value.custom_id || '--'
        },
        {
          label: this.$t('pages.inventory.stocks.history.details.barcode'),
          key: 'barcode',
          visible: true,
          formatter: (value) => value.barcode || '--'
        },
        {
          label: this.$t('pages.inventory.stocks.history.details.location'),
          key: 'location',
          visible: true,
          formatter: (value) => {
            if (value.location_from && value.location_to) {
              return value.location_from + ' → ' + value.location_to
            }
            return value.location || '--'
          }
        },
        {
          label: this.$t('common.headers.amount.title'),
          key: 'qty',
          visible: true,
          class: 'text-right',
          formatter: (value) => value.qty || '--'
        },
        {
          label: this.$t('pages.products.importer.properties.price.label'),
          key: 'selling_price',
          visible: true,
          class: 'text-right',
          formatter: (value) => this.getPrice(value, 'amount.gross')
        },
        {
          label: this.$t(
            'pages.products.importer.properties.purchase_price.label'
          ),
          key: 'pruchase_price',
          visible: true,
          class: 'text-right',
          formatter: (value) => this.getPrice(value, 'purchase_price')
        }
      ].filter(({ key }) => this.showAttributes || key !== 'attributes')
    }
  },

  mounted() {
    if (this.hasReasons) {
      this.columns.push({
        label: this.$t('pages.stock_movements.all.table.reason'),
        key: 'reason',
        visible: true,
        class: 'max-w-xs whitespace-normal',
        formatter: (value) => value.reason || '--'
      })
    }
  },

  methods: {
    getAttributes(value) {
      return Object.values(value.attributes || {}).join(' | ')
    },
    getPrice(item, field) {
      const price = safeGet(item, 'prices.default_prices.0.' + field)
      if (!price) return '--'
      return this.$formatCurrency(
        price,
        safeGet(item, 'prices.default_prices.0.currency')
      )
    }
  }
}
</script>
