<template>
  <th-page-wrapper>
    <div class="p-8">
      <div class="flex items-center">
        <th-popover
          :text="$t('pages.inventory.stocks.manager.help')"
          placement="bottom"
          class="mr-2 pb-1"
        />

        <div
          v-for="tab in tabs"
          :key="tab.key"
          class="item block cursor-pointer px-4 py-1 text-sm border-transparent"
          :class="{ active: activeTab === tab.key }"
          @click="setTab(tab)"
          v-text="tab.label"
        />
      </div>

      <component :is="activeTab" />
    </div>
  </th-page-wrapper>
</template>

<script>
import GoodsBookIn from './goods-book-in'
import GoodsBookOut from './goods-book-out'
import Relocation from './relocation'

export default {
  metaInfo() {
    return {
      title: this.$t('nav.main.items.inventory.stock_manager')
    }
  },

  components: {
    GoodsBookIn,
    GoodsBookOut,
    Relocation
  },

  data() {
    return {
      tabs: [
        {
          key: 'goods-book-in',
          label: this.$t('nav.main.items.inventory.goods_in'),
          event: 'stockmgmtBookinTabClick'
        },
        {
          key: 'goods-book-out',
          label: this.$t('nav.main.items.inventory.goods_out'),
          event: 'stockmgmtBookinOutTabClick'
        },
        {
          key: 'relocation',
          label: this.$t('nav.main.items.inventory.relocation'),
          event: 'stockmgmtRelocateTabClick'
        }
      ],
      activeTab: null
    }
  },

  mounted() {
    this.activeTab = this.$route.query.tab || this.tabs[0].key
  },

  methods: {
    setTab(tab) {
      if (this.activeTab === tab.key) return
      this.activeTab = tab.key
      this.$ampli.eventWithBaseProps(tab.event)
      this.$router.replace({ query: { ...this.$route.query, tab: tab.key } })
    }
  }
}
</script>

<style scoped>
.item {
  border-bottom-width: 3px;
}

.item.active {
  font-weight: bold;
  color: var(--primary-color);
  border-color: var(--primary-color) !important;
  line-height: var(--el-line-height);
}
</style>
