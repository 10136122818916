<template>
  <th-page-wrapper>
    <th-wrapper header-class="bg-th-primary-light" body-class="p-0">
      <!-- Book in form -->
      <template #header-additional>
        <el-form ref="form" :model="formModel" :rules="rules" class="pb-4">
          <el-row :gutter="20" class="pb-4">
            <!-- Location -->
            <el-col :lg="4" :md="8" :sm="8">
              <el-form-item
                :label="$t('pages.inventory.stocks.common.location.title')"
                prop="locationId"
                for="locationId"
              >
                <search-select
                  id="locationId"
                  v-model="formModel.locationId"
                  :list="locations"
                  :loading="loadingLocations"
                  @resource-set="formModel.selectedLocation = $event"
                />
              </el-form-item>
            </el-col>

            <!-- Purchase Order -->
            <el-col :lg="14" :md="8" :sm="16">
              <el-form-item
                :label="$t('common.resource.purchase_order.singular')"
                prop="purchaseOrder"
                for="purchaseOrder"
              >
                <search-select
                  id="purchaseOrder"
                  v-model="formModel.purchaseOrderId"
                  :list="purchaseOrders"
                  :loading="loadingPurchaseOrders"
                  label-field="purchaseOrderNumber"
                />
              </el-form-item>
            </el-col>
            <!-- Consignment Note -->
            <el-col :lg="6" :md="4" :sm="8">
              <el-form-item
                :label="$t('pages.inventory.stocks.goods_in.delivery_note')"
                prop="consignmentNote"
                for="consignmentNote"
              >
                <el-input
                  id="consignmentNote"
                  v-model="formModel.consignmentNote"
                  :placeholder="
                    $t('pages.inventory.stocks.goods_in.delivery_note')
                  "
                  clearable
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="mb-4 items-end">
            <el-col :lg="12" :md="8" :sm="8">
              <el-form-item
                :label="$t('pages.reports.statistics.products.product')"
                prop="productId"
                for="productId"
                class="mb-0"
              >
                <products-search
                  id="productId"
                  v-model="formModel.productId"
                  :result-filter="filterProducts"
                  :custom-product-search-query="customProductSearchQuery"
                  @select-product="formModel.selectedProduct = $event"
                />
              </el-form-item>
            </el-col>
            <el-col :lg="3" :md="4" :sm="10" :xs="12">
              <th-number-input
                v-model="formModel.quantity"
                :disabled="!isStandardProductSelected"
                :locale="$i18n.locale"
                :precision="STOCK_INPUT_PRECISION"
                positive-only
              />
            </el-col>
            <el-col :lg="3" :md="4" :sm="10" :xs="12">
              <el-button
                plain
                :disabled="!formModel.selectedProduct"
                @click="addProduct"
              >
                {{ $t('common.interactions.buttons.add') }}
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <th-table :data="addedProducts" :columns="columns" disable-pagination>
        <template #thTableCell:quantityReceived="{ thTableCell, thTableItem }">
          <th-number-input
            v-if="thTableItem.type !== 'variant_product'"
            :model-value="thTableCell"
            :locale="$i18n.locale"
            :upper-limit="thTableItem.quantityOrdered"
            :precision="STOCK_INPUT_PRECISION"
            positive-only
            @update:modelValue="
              handleQuantityChange({
                product: thTableItem,
                quantityReceived: $event
              })
            "
          />
        </template>
        <template #thTableCell:name="{ thTableCell }">
          <p>
            {{ thTableCell }}
          </p>
        </template>
      </th-table>
    </th-wrapper>
    <el-button
      type="primary"
      class="save-btn"
      :disabled="!isFormValid"
      @click="handleSave"
    >
      {{ $t('common.interactions.buttons.save') }}
    </el-button>
  </th-page-wrapper>
</template>

<script setup>
import { ref, reactive, computed, watch, inject, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import th from '@tillhub/javascript-sdk'
import SearchSelect from '@/components/select/search'
import ThTable from '@/components/table'
import ProductsSearch from '../components/products-search'

const EMPTY_CELL_TXT = '--'
const STOCK_INPUT_PRECISION = 3

const initialFormModel = {
  locationId: null,
  selectedLocation: null,
  purchaseOrderId: null,
  selectedPurchaseOrder: null,
  consignmentNote: null,
  productId: null,
  selectedProduct: null,
  quantity: 0
}

const ampli = inject('ampli')
const logException = inject('logException')
const store = useStore()
const route = useRoute()
const { t } = useI18n()

const formModel = reactive({ ...initialFormModel })
const locations = ref([])
const loadingLocations = ref(false)
const purchaseOrders = ref([])
const loadingPurchaseOrders = ref(false)

const addedProducts = ref([])

const isFormValid = computed(
  () => !!formModel.locationId && areAddedProductsValid.value
)

const areAddedProductsValid = computed(() => {
  return (
    addedProducts.value.filter(
      (addedProduct) =>
        addedProduct.quantityReceived > 0 &&
        (addedProduct.quantityOrdered
          ? addedProduct.quantityReceived <= addedProduct.quantityOrdered
          : true)
    ).length > 0
  )
})

const currentLocation = computed(
  () => store.getters['Config/getCurrentLocation']
)
const currentLocationName = computed(
  () => store.getters['Config/getCurrentBranchName']
)

const columns = computed(() => [
  {
    label: t('pages.products.all.headers.name'),
    key: 'name',
    visible: true,
    formatter: (value) => value.name || EMPTY_CELL_TXT
  },
  {
    label: t('pages.inventory.stocks.history.details.article_number'),
    key: 'custom_id',
    visible: true,
    formatter: (value) => value.custom_id || EMPTY_CELL_TXT
  },
  {
    label: t('pages.products.all.headers.attributes'),
    key: 'attributes',
    visible: true,
    formatter: (value) => Object.values(value.attributes || {}).join(' | ')
  },
  {
    label: t('pages.inventory.stocks.goods_in.quantity_ordered'),
    key: 'quantityOrdered',
    visible: true,
    class: 'text-right w-40',
    formatter: (value) => value.quantityOrdered || EMPTY_CELL_TXT
  },
  {
    label: t('pages.stock_movements.all.table.open_delivery'),
    key: 'openDelivery',
    visible: true,
    class: 'text-right w-40',
    formatter: (value) => value.openDelivery || EMPTY_CELL_TXT
  },
  {
    label: t('pages.inventory.stocks.goods_in.quantity_received'),
    key: 'quantityReceived',
    visible: true,
    class: 'text-right w-40'
  }
])

const rules = computed(() => ({
  locationId: [
    {
      required: true,
      message: t('common.forms.rules.field_warnings.required'),
      trigger: 'blur'
    }
  ]
}))

const isStandardProductSelected = computed(
  () => formModel.selectedProduct?.type === 'product'
)

function filterProducts(products) {
  const parentProducts = products.filter(
    (product) => product.type !== 'variant'
  )
  const availableParentProducts = parentProducts.filter(
    (product) =>
      !addedProducts.value.some(
        (addedProduct) => addedProduct.id === product.id
      )
  )
  const availableProducts = products.filter(
    (product) =>
      !addedProducts.value.some(
        (addedProduct) => addedProduct.id === product.id
      )
  )
  if (!formModel.selectedPurchaseOrder) {
    return availableParentProducts
  } else {
    const purchaseOrderProductIds = (
      formModel?.selectedPurchaseOrder?.products ?? []
    ).map((product) => product.productId)
    return availableProducts.filter((product) =>
      purchaseOrderProductIds.includes(product.id)
    )
  }
}

function customProductSearchQuery(q) {
  return {
    query: {
      limit: 50,
      deleted: false,
      exclude_system_products: true,
      stockable: true,
      is_service: false,
      type: [
        'product',
        'variant_product',
        'variant',
        'linked_product',
        'composed_product'
      ],
      q
    }
  }
}

function handleQuery() {
  if (route.query.purchaseOrderId) {
    formModel.purchaseOrderId = route.query.purchaseOrderId
    formModel.selectedPurchaseOrder =
      purchaseOrders.value.find(
        (purchaseOrder) => purchaseOrder.id === route.query.purchaseOrderId
      ) ?? {}
  }
  if (route.query.location) {
    formModel.locationId = route.query.location
  }
  if (route.query.product) {
    formModel.productId = route.query.product
  }
}

async function fetchLocations() {
  try {
    loadingLocations.value = true
    const { data = [] } = await th
      .stocks()
      .getLocations({ query: { deleted: false } })
    locations.value = data
    if (!currentLocation.value) {
      selectFirstLocation()
    }
  } catch (err) {
    logException(err, { trackError: false })
  } finally {
    loadingLocations.value = false
  }
}

async function fetchPurchaseOrders() {
  try {
    loadingPurchaseOrders.value = true
    const { data = [] } = await th.purchaseOrders().getAll({
      query: { status: 'sent' }
    })
    purchaseOrders.value = data
  } catch (err) {
    logException(err, { trackError: false })
  } finally {
    loadingPurchaseOrders.value = false
  }
}

async function fetchPurchaseOrder(purchaseOrderId) {
  try {
    const { data = [] } = await th.purchaseOrders().get(purchaseOrderId)
    return data
  } catch (err) {
    logException(err, { trackError: false })
    return {}
  }
}

function selectFirstLocation() {
  const branches = locations.value.filter(
    (location) => location.type === 'branch'
  )
  if (branches.length === 1) {
    formModel.selectedLocation = branches[0]
    formModel.locationId = branches[0].id
  }
}

function setDefaultSelectedLocation() {
  formModel.locationId = currentLocation.value
  formModel.selectedLocation = {
    id: currentLocation.value,
    name: currentLocationName.value
  }
}

function addProduct() {
  if (formModel.selectedProduct) {
    let purchaseOrderProduct = null
    if (formModel.selectedPurchaseOrder) {
      purchaseOrderProduct = formModel.selectedPurchaseOrder.products.find(
        (product) => product.productId === formModel.selectedProduct.id
      )
    }
    addedProducts.value.push({
      ...formModel.selectedProduct,
      quantityOrdered: purchaseOrderProduct
        ? purchaseOrderProduct.quantity
        : null,
      openDelivery: purchaseOrderProduct
        ? purchaseOrderProduct.quantity - purchaseOrderProduct.quantityReceived
        : null,
      quantityReceived: formModel.quantity
    })
    if (formModel.selectedProduct?.children?.length > 0) {
      formModel.selectedProduct.children.forEach((productVariant) =>
        addedProducts.value.push({
          ...productVariant,
          parentId: formModel.selectedProduct?.id,
          type: 'variant',
          quantityOrdered: productVariant.quantity,
          openDelivery:
            productVariant.quantity - productVariant.quantityReceived,
          quantityReceived: 0
        })
      )
    }
    formModel.selectedProduct = null
    formModel.productId = null
    formModel.quantity = 0
  }
}

function handleQuantityChange({ product, quantityReceived }) {
  product.quantityReceived = quantityReceived
  if (product.type === 'variant') {
    const variantProducts = addedProducts.value.filter(
      (addedProduct) => addedProduct.parentId === product.parentId
    )
    addedProducts.value = addedProducts.value.map((addedProduct) => {
      if (addedProduct.id === product.parentId) {
        addedProduct.quantityReceived = variantProducts.reduce(
          (sum, currentProduct) => sum + currentProduct.quantityReceived,
          0
        )
      }
      return addedProduct
    })
  }
}

async function handleSave() {
  const body = {
    location: formModel.locationId,
    ...(formModel.consignmentNote
      ? { consignmentNoteNumber: formModel.consignmentNote }
      : {}),
    ...(formModel.purchaseOrderId
      ? { purchaseOrderId: formModel.purchaseOrderId }
      : {}),
    items: formatProductsBody(addedProducts.value)
  }
  try {
    ampli.eventWithBaseProps('stockmgmtCreateButtonClick')
    await th.productsV4().bulkBookStock({ body })
    ElMessage.success({
      message: t('common.success.action.create.single', {
        resource: t('pages.inventory.stocks.goods_in.consignment_note')
      })
    })
    Object.assign(formModel, initialFormModel)
    addedProducts.value = []
  } catch (error) {
    ElMessage.error({ message: error.message })
    logException(error, {
      trackError: false
    })
  }
}

function formatProductsBody(products) {
  return products
    .filter((product) => {
      // removing variant_product is most likely a temporary solution
      // to avoid double stock booking for variant parents
      if (product.type === 'variant_product') {
        return false
      }
      // In case no qty we shouldn't send the product since API won't accept qty 0 or null
      if (!product.quantityReceived) {
        return false
      }
      return true
    })
    .map((product) => ({
      productId: product.id,
      qty: product.quantityReceived
    }))
}

watch(currentLocation, () => {
  setDefaultSelectedLocation()
})

watch(
  () => formModel.selectedPurchaseOrder,
  () => {
    formModel.consignmentNote =
      formModel.selectedPurchaseOrder?.purchaseOrderNumber ?? null
  }
)

watch(
  () => formModel.purchaseOrderId,
  async () => {
    let purchaseOrder = null
    if (formModel.purchaseOrderId) {
      purchaseOrder = await fetchPurchaseOrder(formModel.purchaseOrderId)
    }
    formModel.selectedProduct = null
    formModel.productId = null
    formModel.selectedPurchaseOrder = purchaseOrder
    addedProducts.value = []
  }
)

onMounted(() => {
  fetchLocations()
  fetchPurchaseOrders()
  if (currentLocation.value) {
    setDefaultSelectedLocation()
  }
  handleQuery()
})
</script>
<style scoped>
.save-btn {
  display: flex !important;
  margin-left: auto;
}
</style>
