import { inject, onBeforeUnmount } from 'vue'
import makeBarcodeReader from '@/views/ims/helpers/makeBarcodeReader'
const barcodeReader = makeBarcodeReader()

/* 
This mixin help with implementation of the barcode scanner

Example:
<script>
import BarcodeReaderMixin from '@/mixins/barcode-reader'

export default {
  setup() {
    function handleBarcode(barcode) {
      console.log("Barcode scanned:", barcode)
    }
    barcodeReaderMixin(handleBarcode)
  },
}
</script>
*/

export default function (handleBarcode) {
  if (!handleBarcode) {
    throw new Error("'handleBarcode' function is not provided")
  }

  const logger = inject('vuejs3-logger')

  // Start listening
  document.querySelector('body').addEventListener('keydown', barcodeListener)

  // Create fake barcode reader function for debugging
  if (process.env.NODE_ENV !== 'production') {
    window.fakeBarcodeReader = (barcode) => {
      for (const i in String(barcode)) {
        document.body.dispatchEvent(
          new KeyboardEvent('keydown', { key: barcode[i] })
        )
      }
      document.body.dispatchEvent(
        new KeyboardEvent('keydown', { key: 'Enter' })
      )
      return barcode
    }
  }

  logger.debug('Barcode reader created')

  onBeforeUnmount(() => {
    // Stop listening
    document
      .querySelector('body')
      .removeEventListener('keydown', barcodeListener)

    // Remove fake barcode reader function
    window.fakeBarcodeReader = undefined

    logger.debug('Barcode reader destroyed')
  })

  function barcodeListener(e) {
    barcodeReader.handleBarcode(e, handleBarcode)
  }
}
