<template>
  <div>
    <th-wrapper header-class="bg-th-primary-light" body-class="p-0">
      <!-- Book out form -->
      <template #header-additional>
        <el-form ref="form" :model="stock" :rules="rules" class="pb-4">
          <el-row :gutter="20">
            <!-- Location -->
            <el-col :lg="4" :md="8" :sm="8">
              <el-form-item
                :label="$t('pages.inventory.stocks.common.location.title')"
                prop="location"
                for="location"
              >
                <search-select
                  id="location"
                  v-model="stock.location"
                  class="w-full"
                  :list="resources.locations"
                  :loading="loadingResources"
                  :computed-fields="['label']"
                  :message-text="productLocationStock"
                  @resource-set="(v) => (stock.selectedLocation = v)"
                />
              </el-form-item>
            </el-col>

            <!-- Product -->
            <el-col :lg="14" :md="8" :sm="16">
              <el-form-item
                :label="$t('pages.reports.statistics.products.product')"
                prop="product"
                for="product"
              >
                <products-search
                  v-model="stock.product"
                  @select-product="selectProduct"
                />
              </el-form-item>
            </el-col>

            <!-- Quantity -->
            <el-col :lg="3" :md="4" :sm="8">
              <el-form-item
                :label="$t('pages.reports.statistics.products.qty')"
                prop="qty"
                for="qty"
              >
                <th-number-input
                  id="qty"
                  ref="qty"
                  v-model="stock.qty"
                  :precision="3"
                  :locale="$i18n.locale"
                />
              </el-form-item>
            </el-col>

            <!-- Book out -->
            <el-col :lg="3" :md="4" :sm="10" :xs="12">
              <el-button
                plain
                class="mt-5 xs:mt-0 mb-5 h-10 max-w-full"
                :class="{ 'el-button--primary': isWhiteLabel }"
                @click="bookStock"
              >
                {{ $t('pages.inventory.stocks.goods_out.book_stock.title') }}
              </el-button>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <!-- Reason -->
            <el-col :lg="12" :md="12" :sm="8">
              <el-form-item
                :label="$t('pages.stock_movements.all.table.reason')"
                prop="reason"
                for="reason"
              >
                <search-select
                  id="reason"
                  v-model="stock.reason"
                  :loading="loadingResources"
                  :list="resources.reasons"
                  class="w-full"
                  @resource-set="(v) => (stock.selectedReason = v)"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>

      <!-- History table -->
      <goods-out-history :history-list="goodsOutHistory" has-reasons />
    </th-wrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import GoodsOutHistory from '../components/stock-actions-history'
import SearchSelect from '@/components/select/search'
import ProductsSearch from '../components/products-search'
import { isUnifiedCommerce } from '@/constants'

function getInitialData() {
  return {
    reason: null,
    selectedReason: null,
    product: null,
    selectedProduct: null,
    location: null,
    selectedLocation: null,
    qty: null
  }
}

export default {
  metaInfo() {
    return {
      title: this.$t('nav.main.items.inventory.goods_out')
    }
  },

  components: {
    GoodsOutHistory,
    ProductsSearch,
    SearchSelect
  },

  data() {
    return {
      stock: getInitialData(),
      goodsOutHistory: [],
      resources: {},
      bookingLoading: false,
      loadingResources: false,
      rules: {
        location: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          }
        ],
        product: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          }
        ],
        qty: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          }
        ]
      }
    }
  },

  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation',
      currentLocationName: 'Config/getCurrentBranchName'
    }),

    productLocationStock() {
      if (!this.stock.location || !this.stock.product) return ''

      const stock = safeGet(this.stock, 'selectedProduct.all_stock')
      if (!stock) {
        return this.$t(
          'pages.products.edit.form.sections.variant_stock.table.no_data'
        )
      }

      const locationStock = stock.find(
        (stock) => stock.location.id === this.stock.location
      )
      if (!locationStock || !locationStock.qty) {
        return this.$t(
          'pages.products.edit.form.sections.variant_stock.table.no_data'
        )
      }

      return (
        this.$t('pages.reasons.all.headers.stock') + ': ' + locationStock.qty
      )
    },

    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },

  watch: {
    currentLocation() {
      this.setDefaultSelectedLocation()
    }
  },

  mounted() {
    this.fetchResources()
    if (this.currentLocation) this.setDefaultSelectedLocation()
    this.handleQuery()
  },

  methods: {
    handleQuery() {
      if (this.$route.query.location) {
        this.stock.location = this.$route.query.location
      }
      if (this.$route.query.product) {
        this.stock.product = this.$route.query.product
      }
    },

    async fetchResources() {
      try {
        this.loadingResources = true
        this.resources = await this.$resourceFetch('reasons', {
          resource: 'locations',
          handler: () => th.stocks().getLocations({ deleted: false })
        })
        if (!this.currentLocation) this.selectFirstLocation()
      } catch (error) {
        this.$logException(error, { trackError: false })
      } finally {
        this.loadingResources = false
      }
    },

    selectFirstLocation() {
      // Select first branch if there is only one
      const branches = this.resources.locations.filter(
        (l) => l.type === 'branch'
      )
      if (branches.length === 1) {
        this.stock.selectedLocation = branches[0]
        this.stock.location = branches[0].id
      }
    },

    setDefaultSelectedLocation() {
      this.stock.location = this.currentLocation
      this.stock.selectedLocation = {
        id: this.currentLocation,
        name: this.currentLocationName
      }
    },

    async getProductDetails(productId) {
      try {
        const { data } = await th
          .products()
          .getDetails(productId, { original_product: true })
        this.stock.selectedProduct = data
      } catch (err) {
        this.$logException(err, { trackError: false })
      }
    },

    selectProduct(product) {
      if (product) {
        this.stock.product = product.id
        const oldProductId = safeGet(this.stock, 'selectedProduct.id')
        if (oldProductId !== product.id) this.getProductDetails(product.id)
        this.$refs.qty.$el.getElementsByTagName('input')['qty'].focus()
      } else {
        this.stock.selectedProduct = null
      }
    },

    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    },

    async bookStock() {
      const valid = await this.validate()
      if (!valid) {
        return this.$message({
          type: 'warning',
          message: this.$t('common.forms.message.invalid_inputs')
        })
      }

      this.$ampli.eventWithBaseProps('stockmgmtBookOutButtonClick')

      const location = this.stock.location
      const locationName = safeGet(this.stock, 'selectedLocation.name')
      const qty = this.stock.qty
      const qtyToSubtract = Math.abs(qty) * -1
      const reason = this.stock.reason || undefined

      try {
        this.bookingLoading = true

        const reqOptions = {
          productId: safeGet(this.stock, 'selectedProduct.id'),
          body: {
            reason,
            location,
            qty: qtyToSubtract
          }
        }

        await th.products().bookStock(reqOptions)

        this.goodsOutHistory.unshift({
          ...this.stock.selectedProduct,
          qty,
          location: locationName,
          reason: safeGet(this.stock, 'selectedReason.name')
        })
        this.resertForm()

        this.$message({
          type: 'success',
          message: this.$t('common.success.action.update.single', {
            resource: this.$t('common.resource.stock.singular')
          })
        })
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.update.single', {
            resource: this.$t('common.resource.stock.singular')
          })
        })
      } finally {
        this.bookingLoading = false
      }
    },

    resertForm() {
      const location = this.stock.selectedLocation
      this.stock = getInitialData()
      this.$refs.form.resetFields()
      this.stock.selectedLocation = location
      this.stock.location = location.id
    }
  }
}
</script>
